import * as React from 'react';
import { Prismic_Project } from '../../graphql-types';
import Img from 'gatsby-image';
import './projectPreviewCard.scss';
// import { Link } from 'gatsby';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

interface IProps {
  projectPreview: Prismic_Project;
  showNameOnHover?: boolean;
}

export const ProjectPreviewCard: React.FC<IProps> = ({ children, projectPreview, showNameOnHover }) => {
  return (
    <>
      <style>
        {`
        .${projectPreview._meta.uid} .preview-images::before {
          border-color: ${projectPreview.transition_colour};
        }
      `}
      </style>
      <AniLink
        to={`/projects/${projectPreview._meta.uid}`}
        cover
        direction="left"
        bg={projectPreview.transition_colour || 'black'}
      >
        <div
          className={`project-preview ${projectPreview._meta.uid}`}
          data-hover-mode={projectPreview.preview_image[0].hover_transition}
        >
          <div className="preview-images">
            <Img
              className="default-image"
              fluid={projectPreview.preview_image[0].default_imageSharp.childImageSharp.fluid}
              fadeIn={true}
              backgroundColor="#F6F6F6"
            />
            <Img
              className="hover-image"
              fluid={projectPreview.preview_image[0].hover_imageSharp.childImageSharp.fluid}
              fadeIn={true}
              backgroundColor="#F6F6F6"
            />
          </div>
          <div className="project-name" data-show-on-hover={showNameOnHover}>
            {projectPreview.name[0].text}
          </div>
        </div>
      </AniLink>
    </>
  );
};
