import * as React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import { ProjectPreviewCard } from '../components/projectPreviewCard';
import Masonry from 'react-masonry-css';
import { ProjectsPageQuery, Prismic_Project } from '../../graphql-types';
import './projects.scss';
interface IProps {
  data: ProjectsPageQuery;
}
const Projects: React.FC<IProps> = (props) => {
  return (
    <Layout
      pageMeta={{
        title: 'Projects | Evangeline Harbury Food Stylist',
        description:
          'Evangeline (Evie) Harbury finished her training at Le Cordon Bleu, London in 2014 and has since been working as a chef, food stylist, recipe developer and home economist.',
        keywords: 'Projects, Chef, Food Stylist, Home Economist',
      }}
    >
      <div className="projects-grid">
        {props.data.prismic.allProjects.edges.map(({ node }) => (
          <ProjectPreviewCard projectPreview={node as Prismic_Project} />
        ))}
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query ProjectsPage {
    prismic {
      allProjects {
        edges {
          node {
            name
            description
            transition_colour
            preview_image {
              default_image
              default_imageSharp {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
              hover_image
              hover_imageSharp {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
              hover_transition
            }
            _meta {
              uid
            }
          }
        }
      }
    }
  }
`;

export default Projects;
